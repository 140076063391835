/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { ImageFallback } from "../../basic/image-fallback";

export const HeaderLogo = () => {
  const qtState = useSelector((state) => state || {});
  const { logo = "" } = get(qtState, ["qt", "config", "pagebuilder-config", "header"], {});
  const darkLogo = get(qtState, ["qt", "config", "pagebuilder-config", "header", "darkLogo"], logo);
  const enableDarkMode = get(qtState, ["header", "isDarkModeEnabled"], false);


  if (!darkLogo || !logo) {
    return <ImageFallback />;
  }
  if (enableDarkMode)
    return (
      <div>
        <img
          class="logoHeight"
          data-test-id="secondary_logo"
          src={darkLogo}
          alt="Dark Mode Logo"
          width="100%"
          height="100%"
        />
      </div>
    );

  return (
    <img
      class="logoHeight"
      data-test-id="secondary_logo"
      src={logo}
      alt="Logo"
      width="100%"
      height="100%"
    />
  );
};
